import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '32, 90, 132',
		'primary-dark': '0, 47, 108',
		'accent': '211, 175, 96',
		'accent-plus': '7, 7, 7',
	},
});
